import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

// Profile
const Profile = lazy(() => import('../../JoshKera/Profile/EditProfile'))
const ChangePassword = lazy(() => import('../../JoshKera/Profile/ChangePassword'))

// Promotions
const Promotions = lazy(() => import('../../JoshKera/Promotions/Promotions'));
const PromoDetails = lazy(() => import('../../JoshKera/Promotions/PromoDetails'));

// Promotion Plans
const PromotionPlans = lazy(() => import('../../JoshKera/PromotionPlans/PromotionPlans'));
const PlanDetails = lazy(() => import('../../JoshKera/PromotionPlans/PlanDetails'));

// Users
const AllQuilters = lazy(() => import('../../JoshKera/Users/Quilters/AllQuilters'));
const QuilterDetails = lazy(() => import('../../JoshKera/Users/Quilters/QuilterDetails'));
const AllVendors = lazy(() => import('../../JoshKera/Users/Vendors/AllVendors'));
const VendorDetails = lazy(() => import('../../JoshKera/Users/Vendors/VendorDetails'));
const PendingVendors = lazy(() => import('../../JoshKera/Users/PendingVendors/PendingVendors'));


//CMS
const BannerManagement = lazy(() => import('../../JoshKera/CMS/BannerManagement/BannerManagement'))
const ManageHome = lazy(() => import('../../JoshKera/CMS/ManageHome/ManageHome'))
const ManageAboutUs = lazy(() => import('../../JoshKera/CMS/ManageAboutUs/ManageAboutUs'))
const CreateBanner = lazy(() => import('../../JoshKera/CMS/BannerManagement/CreateBanner'))

const TermsConditions = lazy(() => import('../../JoshKera/CMS/TermsConditions/TermsConditions'))
const CreateTermsConditions = lazy(() => import('../../JoshKera/CMS/TermsConditions/CreateTermsConditions'))
const PrivacyPolicy = lazy(() => import('../../JoshKera/CMS/PrivacyPolicy/PrivacyPolicy'))
const CreatePrivacyPolicy = lazy(() => import('../../JoshKera/CMS/PrivacyPolicy/CreatePrivacyPolicy'))
const FAQs = lazy(() => import('../../JoshKera/CMS/FAQs/FAQs'))
const CreateFAQs = lazy(() => import('../../JoshKera/CMS/FAQs/CreateFAQs'))
const ContactUs = lazy(() => import('../../JoshKera/CMS/ContactUs/ContactUs'))

// Settings
const SiteSettings = lazy(() => import('../../JoshKera/Settings/SiteSettings'))

// Version
const Version = lazy(() => import('../../JoshKera/Version/Version'))

// Not Found
const NotFound = lazy(() => import('../../container/pages/404'));

// Coming Soon
const ComingSoon = lazy(() => import('../../container/pages/ComingSoon'));


const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        {/*Dashboard */}
        <Route path="admin/dashboard" element={<ComingSoon />} />
        <Route path="admin/editprofile" element={<Profile />}  />
        <Route path="admin/password/edit" element={<ChangePassword />}  />

        {/* Promotions */}
        <Route path="admin/promotions" element={<Promotions />} />
        <Route path="admin/promotions/details" element={<PromoDetails />} />

        {/* Promotion Plans */}
        <Route path="admin/promotion-plans" element={<PromotionPlans />} />
        <Route path="admin/promotion-plans/details" element={<PlanDetails />} />

        {/* Users */}
        <Route path="admin/users/quilters" element={<AllQuilters />} />
        <Route path="admin/users/quilter-details" element={<QuilterDetails />} />
        <Route path="admin/users/vendors" element={<AllVendors />} />
        <Route path="admin/users/vendor-details" element={<VendorDetails />} />
        <Route path="admin/users/pending-vendors" element={<PendingVendors />} />

        {/* CMS */}
        <Route path="admin/cms/contact-us" element={<ContactUs />} />

        <Route path="admin/cms/bannerManagement" element={<BannerManagement />} />
        <Route path="admin/cms/bannerManagement/create" element={<CreateBanner />} />
        <Route path="admin/cms/bannerManagement/edit" element={<CreateBanner />} />

        <Route path="admin/cms/terms&Conditions" element={<TermsConditions />} />
        <Route path="admin/cms/terms&Conditions/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/terms&Conditions/edit" element={<CreateTermsConditions />}  />

        <Route path="admin/cms/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="admin/cms/privacypolicy/create" element={<CreatePrivacyPolicy />}  />
        <Route path="admin/cms/privacypolicy/edit" element={<CreatePrivacyPolicy />}  />

        <Route path="admin/cms/faqs" element={<FAQs />} />
        <Route path="admin/cms/faqs/create" element={<CreateFAQs />}  />
        <Route path="admin/cms/faqs/edit" element={<CreateFAQs />}  />

        <Route path="admin/cms/refundpolicy/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/refundpolicy/edit" element={<CreateTermsConditions />}  />

        <Route path="admin/cms/manage-home" element={<ManageHome />} />
        <Route path="admin/cms/manage-about" element={<ManageAboutUs />} />
      
        {/* Settings */}
        <Route path="admin/settings" element={<SiteSettings />} />

        {/* Version */}
        <Route path="admin/version" element={<Version />} />
        
        {/* Not Found */}
        {/* <Route path="*" element={<NotFound />} /> */}
        <Route path="*" element={<Navigate to="/admin/dashboard" />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);