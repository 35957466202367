import {
  UilCreateDashboard,
  UilFileShieldAlt,
  UilSetting,
  UilUsersAlt,
  UilDocumentLayoutRight,
} from "@iconscout/react-unicons";
import { Menu } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import UilEllipsisV from "@iconscout/react-unicons/icons/uil-ellipsis-v";
import propTypes from "prop-types";
import {
  changeDirectionMode,
  changeLayoutMode,
  changeMenuMode,
} from "../redux/themeLayout/actionCreator";

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const dispatch = useDispatch();

  const path = "/admin";

  const pathName = window.location.pathname;
  const pathArray = pathName ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : "";
  const mainPathSplit = mainPath.split("/");

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const changeLayout = (mode) => {
    dispatch(changeLayoutMode(mode));
  };
  const changeNavbar = (topMode) => {
    const html = document.querySelector("html");
    if (topMode) {
      html.classList.add("ninjadash-topmenu");
    } else {
      html.classList.remove("ninjadash-topmenu");
    }
    dispatch(changeMenuMode(topMode));
  };
  const changeLayoutDirection = (rtlMode) => {
    if (rtlMode) {
      const html = document.querySelector("html");
      html.setAttribute("dir", "rtl");
    } else {
      const html = document.querySelector("html");
      html.setAttribute("dir", "ltr");
    }
    dispatch(changeDirectionMode(rtlMode));
  };

  const darkmodeActivated = () => {
    document.body.classList.add("dark-mode");
  };

  const darkmodeDiactivated = () => {
    document.body.classList.remove("dark-mode");
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
        {t("dashboard")}
      </NavLink>,
      "dashboard",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
          <UilCreateDashboard />
        </NavLink>
      )
    ),
    getItem(t("Users"), "users", !topMenu && <UilUsersAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/pending-vendors`}>
          {t("Pending Vendors")}
        </NavLink>,
        "pending-vendors",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/quilters`}>
          {t("All Quilters")}
        </NavLink>,
        "quilters",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/users/vendors`}>
          {t("All Vendors")}
        </NavLink>,
        "vendors",
        null
      ),
    ]),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/promotions`}>
        {t("Promotions")}
      </NavLink>,
      "promotions",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/promotions`}>
          <UilDocumentLayoutRight />
        </NavLink>
      )
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/promotion-plans`}>
        {t("Promotions Plans")}
      </NavLink>,
      "plans",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/promotions-plans`}>
          <UilDocumentLayoutRight />
        </NavLink>
      )
    ),

    getItem(t("CMS"), "cms", !topMenu && <UilFileShieldAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/bannerManagement`}>
          {t("Banner Management")}
        </NavLink>,
        "bannerManagement",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/manage-home`}>
          {t("Manage Home")}
        </NavLink>,
        "homePage",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/manage-about`}>
          {t("Manage About")}
        </NavLink>,
        "aboutUs",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/terms&Conditions`}>
          {t("Terms and Conditions")}
        </NavLink>,
        "terms&Conditions",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/privacypolicy`}>
          {t("Privacy Policy")}
        </NavLink>,
        "privacypolicy",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/faqs`}>
          {t("FAQs")}
        </NavLink>,
        "faqs",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/contact-us`}>
          {t("Contact Us")}
        </NavLink>,
        "contactus",
        null
      ),
    ]),
    getItem(t("Settings"), "settings", !topMenu && <UilSetting />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/settings`}>
          {t("Site Settings")}
        </NavLink>,
        "site-settings",
        null
      ),
    ]),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/version`}>
        {t("Version")}
      </NavLink>,
      "version",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/version`}>
          <UilDocumentLayoutRight />
        </NavLink>
      )
    ),
  ];
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? "home"
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
          : []
      }
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;